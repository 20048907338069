import React from "react"
import Helmet from "react-helmet"

import "../styles/index.css"

const indexPage = () => {
  return (
    <div className="container">
      <Helmet>
        <title>iamJohnBarker</title>
        <meta name="description" content="The website of John Barker" />
      </Helmet>

      <div className="nav">
        <div className="nav__text">iamJohnBarker.com</div>
      </div>

      <div className="heading">
        <div className="heading__text">iamJohnBarker</div>
        <div className="heading__btn">
          I'm currently working at
          <a href="https://heretorecord.com" className="heading__btn-button">
            Here to Record
          </a>
          on lots of
          <a
            href="https://youtube.com/heretorecord"
            className="heading__btn-button"
          >
            fun video projects
          </a>
        </div>

        <div className="sub-heading">
          <div className="sub-heading__text">
            I'm also building{" "}
            <a href="https://heretorecord.com/graphics">
              video production software
            </a>{" "}
            and <a href="https://ritzmultiplex.com">designing websites</a>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer__text">❤</div>
      </div>
    </div>
  )
}

export default indexPage
